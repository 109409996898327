var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"base-chip",class:{
    'base-chip--small': _vm.small,
    'base-chip--medium': _vm.medium,
    'base-chip--large': _vm.large,
    'base-chip--xl': _vm.xl,
    'base-chip--xxl': _vm.xxl,
    'base-chip--purple': _vm.purple,
    'base-chip--yellow': _vm.yellow,
    'base-chip--red': _vm.red,
    'base-chip--blue': _vm.blue,
    'base-chip--green': _vm.green,
  }},[(_vm.value)?[_vm._v(" "+_vm._s(_vm.value)+" ")]:[_vm._t("default")]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }