<template>
  <div class="free-shift-list-page new-design">
    <loader v-if="loading" />

    <div v-else class="free-shift-list-page__wrapper">
      <div class="free-shift-list-page__title">Свободные</div>

      <base-chip blue xxl>
        <span class="body-2-mobile">Часов:</span>
        <span class="button-medium">{{ !!totalHours ? totalHours : 0 }}</span>
      </base-chip>

      <base-table :columns="columns" :data="freeShiftList" @click-link="openSchedule">
        <template #index="{ value }">
          <span class="color-neutral-600">
            {{ value + 1 }}
          </span>
        </template>

        <template #turn_date="{ value }">
          <span class="color-neutral-600">{{ value.dayOfWeek }}</span>
          <span class="ml-ex-6">{{ value.date }}</span>
        </template>

        <template #link="{ value }">
          <base-icon-button
            class="desktop-schedule-view__button"
            icon-path="arrow-up-right-line.svg"
            width="16"
            height="16"
            extra-small
            @click="openSchedule(value)"
          />
        </template>
      </base-table>
    </div>
  </div>
</template>

<script setup>
import { request } from "@/components-js/requestSrv";
import { computed, ref } from "vue";
import { formattedDate } from "@/components-js/dateFormat";
import { formattedSummarizeWorkTime, formattedTime } from "@/views/ShiftListPage/helpers";
import BaseTable from "@/components/BaseTable";
import Loader from "@/components/Loader.vue";
import BaseIconButton from "@/components/BaseIconButton/BaseIconButton.vue";
import { useRouter } from "vue2-helpers/vue-router";
import BaseChip from "@/components/BaseChip/BaseChip.vue";

const router = useRouter();

const loading = ref(false);
const freeShiftList = ref([]);

const totalHours = computed(() => {
  return freeShiftList.value.reduce((acc, freeShift) => acc + parseInt(freeShift.hours), 0);
});

const columns = computed(() => [
  {
    id: "index",
    label: "№",
  },
  {
    id: "turn_date",
    label: "Дата",
    format: row => {
      return { dayOfWeek: formattedDate(row.turn_date).dayOfWeek, date: formattedDate(row.turn_date).dateFormatted };
    },
  },
  { id: "point", label: "Точка" },
  {
    id: "time_begin",
    label: "Смена",
    format: row => {
      return formattedTime(row) + " - " + formattedTime(row, false);
    },
  },
  {
    id: "hours",
    label: "Часов",
    format: row => {
      return formattedSummarizeWorkTime(row);
    },
  },
  { id: "link", label: "Link" },
]);

const load = async () => {
  loading.value = true;

  freeShiftList.value = await request("/api/turn/listfree", "POST");

  loading.value = false;
};

const openSchedule = row => {
  router.push({ name: "schedule", query: { dateFrom: row.turn_date, dateTo: row.turn_date, pointId: row.id_point } });
};

load();
</script>

<style scoped lang="scss">
@import "../../../styles/ui/breakPoints";
@import "../../../styles/ui/fontSize";

.free-shift-list-page {
  display: flex;
  justify-content: center;

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--sp-6);
    padding-top: var(--sp-8);
  }

  &__title {
    display: none;
    @include add-font-face("h2");
  }

  @include mobile-only() {
    padding: 0 var(--sp-4);

    &__title {
      display: block;
    }

    ::v-deep .base-chip {
      width: 100%;
      max-width: 100%;
    }
  }
}
</style>
