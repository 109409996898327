<template>
  <div class="base-table" :style="{ '--columns-count': columns.length }">
    <template v-if="isMobile">
      <div class="base-table__row" v-for="(row, k) in data" :key="k">
        <div class="base-table__header">
          <div class="base-table__date">
            <span class="">{{ formattedDate(row.turn_date).dateFormatted }}</span>
            <span class="color-neutral-400 ml-1">{{ formattedDate(row.turn_date).dayOfWeek }}</span>
          </div>
          <div class="base-table__point">{{ row.point }}</div>
          <div class="base-table__period">{{ formattedTime(row) }} - {{ formattedTime(row, false) }}</div>
          <div class="base-table__hour color-neutral-400">{{ formattedSummarizeWorkTime(row) }}</div>
        </div>
        <div class="base-table__footer">
          <div class="base-table__tag"></div>
          <div class="ml-2">
            <base-icon
              path="arrow-up-right-line.svg"
              width="16"
              height="16"
              screen-size-dependency
              @click="$emit('click-link', row)"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="base-table__cell base-table__cell--header" v-for="(col, index) in columns" :key="80797 + index">
        {{ col.label }}
      </div>

      <template v-for="(row, k) in data">
        <div class="base-table__cell" v-for="(col, index) in columns" :key="row.point_id + col + k + index + '2f'">
          <slot :name="[col.id]" :value="getValue(col, row, k)">
            {{ col.format ? col.format(row) : row[col.id] }}
          </slot>
        </div>
      </template>
    </template>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { DEVICE_TYPES } from "@/lib/helpers/checkDevice";
import { useStore } from "vue2-helpers/vuex";
import { formattedSummarizeWorkTime, formattedTime } from "@/views/ShiftListPage/helpers";
import { formattedDate } from "@/components-js/dateFormat";
import BaseIcon from "@/components/BaseIcon/BaseIcon.vue";

const { columns, data } = defineProps({
  columns: {
    type: Array,
    required: true,
  },
  data: {
    type: Array,
    required: true,
  },
});

const store = useStore();
const isMobile = computed(() => store?.state?.breakPoint === DEVICE_TYPES.MOBILE);

const getValue = (col, row, index) => {
  if (col.id === "index") {
    return index;
  } else if (col.id === "link") {
    return row;
  } else {
    return col.format ? col.format(row) : row[col.id];
  }
};
</script>

<style scoped lang="scss">
@import "../../../styles/ui/breakPoints";
@import "../../../styles/ui/fontSize";

.base-table {
  display: grid;
  grid-template-columns: repeat(var(--columns-count), auto);
  border-radius: 5px;
  border: 2px solid var(--color-neutral-100);
  border-bottom: none;

  &__cell {
    display: flex;
    align-items: center;
    padding: var(--sp-3) var(--sp-6);
    border-bottom: 2px solid var(--color-neutral-100);

    &--header {
      background: var(--color-neutral-50);
      color: var(--color-neutral-500);
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    gap: var(--sp-3);
    padding: var(--sp-ex-10);
    border-radius: 5px;
    background: var(--color-neutral-50);
    @include add-font-face("body-4");
  }

  &__header {
    display: grid;
    grid-template-columns:
      minmax(10px, 20fr)
      minmax(10px, 35fr)
      minmax(10px, 26fr)
      minmax(10px, 18fr);
    max-width: 1149px;
    min-width: 100%;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }

  &__tag {
    @include add-font-face("body-2");
  }

  &__hour,
  &__period {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  @include mobile-only() {
    grid-template-columns: 1fr;
    row-gap: var(--sp-5);
    border: none;

    &__cell {
      &--header {
        display: none;
      }
    }
  }
}
</style>
